:root {
  --primary-color: #6f2da8;
  --secondary-color: #b289d5;
}
@font-face {
  font-family: "circular";
  src: url("https://cerclex-public-static-content.s3.ap-south-1.amazonaws.com/CircularStd-Book.otf");
}

@font-face {
  font-family: "circular-light";
  src: url("../src/fonts/circular/CircularStd-Light.otf");
}

@font-face {
  font-family: "circular-bold";
  src: url("https://cerclex-public-static-content.s3.ap-south-1.amazonaws.com/CircularStd-Bold.otf");
}

/*! To be added to global css */
.input-field-style {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  padding: 10px 10px;
  height: 54px;
  background-color: #fff;
  border: 1px solid #dadae7;
  margin-top: 1rem;
}

.select-vehicle :hover {
  background: #ece4f4;
  cursor: pointer;
}

.edit-btn {
  position: absolute;
  z-index: 2;
  background-color: #6f2da8;
  right: -5px;
  bottom: 5px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-bar {
  width: 100px;
  height: 20px;
  border-radius: 25px;
  overflow: hidden;
  background-color: #4ea654;
}
.main-progress-bar {
  width: 555px;
  height: 20px;
  border-radius: 25px;
  background-color: rgba(234, 236, 240, 1);
}
.edit-btn-organization {
  position: absolute;
  z-index: 2;
  background-color: #6f2da8;
  right: 0px;
  bottom: 0px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.raise-pickup-btn {
  padding: 12px 40px;
  border-radius: 30px;
  background: linear-gradient(94deg, #6f2da8 0%, #b055ff 100%);
  box-shadow: 0px 14px 16px 0px rgba(111, 45, 168, 0.24);
  color: #ffffff;
}

.raise-pickup-btn[disabled] {
  padding: 12px 40px;
  border-radius: 30px;
  background: linear-gradient(94deg, #757575 0%, #b0b0b0 100%);
  box-shadow: 0px 14px 16px 0px rgba(117, 117, 117, 0.24);
}

.upload-button {
  cursor: pointer;
  display: inline-block;
  padding: 1px 2px;
  border: none;
  margin-top: 1px;
}

.upload-img {
  width: 300px;
  height: 340px;
}

.phone-input-style {
  /* padding-left: 0% !important; */
  width: 88% !important;
}

.input-field-style > input {
  width: 100%;
  border: none;
  outline: none;
}

.new-input {
  width: 100%;
  background: #fafafa !important;
  border-radius: 8px !important;
  height: 30px;
  padding-left: 10px !important;
}

.scrap-select-dropdown {
  background-color: #fafafa;
  border-radius: 8px;
  height: 45px;
  appearance: none;
  padding: 10px;
  color: #a7a7a7;
  border: none;
}
.scrap-img {
  width: 100% !important;
  height: 400px;
  border: 2px dashed #6f2da8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.scrap-upload-img {
  width: 30%;
  height: 400px;
  border-radius: 10px;
  background-color: #fff;
}

.upload-btn {
  background-color: #ece4f4;
  color: #6f2da8;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 88px;
  border-radius: 10px;
  padding: 20px;
}
/*! to be added to global css */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "circular";
}

.dashboard-card-img {
  width: 64px;
  height: 64px;
}

.line1 {
  height: 4vh;
  border-radius: 15px;
  width: 100%;
  margin-top: 2px;
  border-radius: 60px;
  background: #ffffff;
  box-shadow: -5px -5px 10px 0px #f8f3ff inset,
    5px 5px 10px 0px rgba(187, 170, 204, 0.5) inset,
    2px 2px 4px 0px rgba(187, 170, 204, 0.25) inset,
    -2px -2px 4px 0px rgba(255, 255, 255, 0.5) inset;
  margin-top: 14px;
  margin-bottom: 5px;
}

.line1-isMobile {
  height: 3vh;
  border-radius: 15px;
  width: 100%;
  margin-top: 2px;
  border-radius: 60px;
  background: #ffffff;
  box-shadow: -5px -5px 10px 0px #f8f3ff inset,
    5px 5px 10px 0px rgba(187, 170, 204, 0.5) inset,
    2px 2px 4px 0px rgba(187, 170, 204, 0.25) inset,
    -2px -2px 4px 0px rgba(255, 255, 255, 0.5) inset;
  margin-top: 14px;
  margin-bottom: 5px;
}

.percent-tag {
  width: 60px;
  height: 60px;
  background-color: #6f2da8;
  position: absolute;
  top: -10px;
  font-size: 13px;
}

.semi-bold {
  color: #888;
}

/* .card-title {
  font-size: 1.2rem;
  font-weight: 600;
} */
.custom-modal {
  width: 80vw;
}

.semibold-notification {
  font-size: 14px;
  font-weight: 450;
  margin-bottom: 5px;
  color: #263238;
  line-height: 18px;
}

.chart-header {
  font-size: 1rem;
  color: #4f4f4f;
  line-height: 17px;
}

.bold {
  font-size: 1.2rem;
  font-weight: 700;
}

.bold-card-title {
  font-size: 1.4rem;
  font-weight: 700;
}

.box {
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
}

/* styles.css (or your preferred CSS file) */
.hide-focus-underline {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  /* Add any other styles you want */
}

.upload-file {
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.circular-font {
  font-family: Arial, Helvetica, sans-serif;
}

.notifcation-unseen-background {
  background: #ebe0f466;
}

.plan {
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  width: 25rem;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.filter-location {
  background-color: #fff;
  margin-top: 0.8rem;
  left: 15rem;
  position: absolute;
  padding: 16px;
  border-radius: 10px;
  width: 20vw;
}

.filter-location input {
  width: 100%;
  height: 20px;
}

.round-background {
  width: 80px;
  height: 80px;
  background-color: #ebe0f4;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.round-background + input {
  position: absolute;
  width: 80px;
  height: 100px;
  opacity: 0;
  cursor: pointer;
}

.user-profile {
  height: 100%;
  display: flex;
  align-items: center;
  /* padding: 0 10px; */
  /* width: 15vw; */
}

.driver-assigned-toast {
  padding: 10px;
}

.header-drop-down {
  background-color: #fff;
  width: 20vw;
  height: 60%;
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: space-between;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 12px #fffefe;
  margin-right: 10px;
}

.default-toast {
  padding: 10px !important;
  min-height: fit-content !important;
  /* border: 1px solid #fff !important; */
}

.user-profile-drop-down {
  width: 20vw !important;
  top: -4rem;
  padding: 10px 0;
}

aside::-webkit-scrollbar {
  width: 0.4em;
}

aside::-webkit-scrollbar-thumb {
  background-color: #b289d5;
  outline: 1px solid #f7f7f7;
  border-radius: 20px;
}

.app-image {
  width: 40px;
}

.chart-date-picker {
  /* background: red; */
  color: #000 !important;
  height: 30px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.chart-date-picker input {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
}

.header-notification {
  width: 30vw !important;
  top: -2.5rem;
  padding-bottom: 2rem;
}

.status-tag {
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  padding-inline: 1rem;
  padding-block: 0.2rem;
  background: #f5fff9;
}

.countrol-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  color: #000;
  border-radius: 6px;
  background: #ece4f4;
  cursor: pointer;
  outline: none;
  border: none;
}

.round-background i {
  font-size: 40px;
  color: #6f2da8;
}

.count-notify-view {
  background: #6f2da8;
  border-radius: 8px;
  padding-block: 4px;
  padding-inline: 10px;
}

.round-background-action {
  border-right: 1px solid #ccc;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  width: 40px;
  display: flex;
  justify-content: center;
  margin-left: -6px;
  color: #666;
}

.inputWrapper {
  height: 60px;
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  /* background-color: #fff; */
  /* border: 1px solid #929292; */
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ebe0f4;
}

.fileInput {
  cursor: pointer;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  font-size: 50px;
  opacity: 0;
  -moz-opacity: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(opacity=0);
}

.contract {
  width: 24vw;
}

.line2 {
  display: flex;
  flex-direction: row;
  animation-name: milestone;
  animation-duration: 1s;
}

.progress-rate {
  background: #6f2da8;
  box-shadow: 0px 7px 10px 0px rgba(255, 255, 255, 0.2) inset;
  height: 4vh;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 60px;
  justify-content: center;
}

.progress-rate-isMobile {
  background: #6f2da8;
  box-shadow: 0px 7px 10px 0px rgba(255, 255, 255, 0.2) inset;
  height: 3vh;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 60px;
  justify-content: center;
}

.colored-bar:hover {
  fill: #6f2da8 !important; /* Adjust the hover color as needed */
}

.vector-img {
  width: 5vh;
  height: 100%;
  object-fit: contain;
  margin-left: -10px;
}

.recent-pickup-card {
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.raise-pickups-sidebar {
  width: 50%;
  /* height: 50vh; */
  overflow-y: auto;
}

.capsule-container {
  border-radius: 20px;
  padding-inline: 0.6rem;
  padding-block: 0.3rem;
  display: flex;
  align-items: center;
}

.dashboard-sidebar {
  width: 20%;
  height: 100%;
}

.main-dashboard {
  display: grid;
  grid-template-columns: 4fr 1fr;
  gap: 20px;
  /* padding-top: 10px; */
}

.group {
  background-image: url("../public/images/group-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 75%;
  height: 30vh;
  padding: 1rem 4rem;
}

a {
  color: #000;
}

.certificate {
  background-color: #fff;
  width: 70%;
  height: 80vh;
  background-image: url("../public/images/certificate-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

#container_2 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.certificate-logo {
  width: 10vw;
  height: 20vw;
  background-color: #6f2da8;
}

.account-img {
  border: 4.2px #6f2da8 dashed;
  border-radius: 50%;
  justify-content: center;
  align-items: centerF;
  width: 120px;
  display: flex;
  height: 120px;
  padding: 6.5px;
  position: relative;
}

.certificate-content {
  width: 50%;
}

.output-container::-webkit-scrollbar {
  width: 0.6em;
}

.output-container::-webkit-scrollbar-thumb {
  background-color: #b289d5;
  outline: 1px solid #f7f7f7;
  border-radius: 20px;
}

.input-style {
  background-color: #fff;
  width: 100%;
  padding: 0px 15px;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #dadae7 !important;
  background: #fff;
  margin-top: 5px;
  outline: none;
  color: #666;
}

.no-margins {
  margin: 0 !important;
}

.input-style ::placeholder {
  color: #dadae7;
}

.input-style input {
  border: none;
  outline: none;
  background: none;
  width: 100%;
  height: 100%;
  font-size: 16px;
  color: #666;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  height: 5rem;
  box-shadow: 7px 0 20px 0 #dddddd;
  padding: 0 1rem;
  /* border-bottom: 2px solid var(--secondary-color); */
  width: 100%;
  margin-left: auto;
  /* background-color: #f9fafb !important; */
}

.overlay {
  height: calc(100vh - 5rem);
  width: 100%;
  display: flex;
}

.full-frame {
  /* border: 10px solid red; */
  height: 100vh;
  /* overflow-y: hidden; */
}

.aside-list {
  display: flex;
  flex-direction: column;
  padding-inline: 1rem;
  height: 100%;
  overflow-y: auto;
  border-right: 5px solid var(--secondary-color);
}

.aside-items-list-open {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
  padding-inline: 1rem;
  gap: 1.5rem;
  margin-top: 2rem;
}

.aside-items-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.2rem;
  margin-top: 2rem;
}

.debug-border {
  border: 4px solid red;
}

.quantity-channelized-chart {
  height: max-content;
  min-height: 300px;
  width: 68%;
}

.aside-list > div > img {
  width: 2.4rem;
  height: 2.4rem;
  margin: 1rem 0;
  cursor: pointer;
  border-radius: 50%;
}

.aside-list-open > div > img {
  width: 40px;
  height: 40px;
  margin: 1rem 0;
  cursor: pointer;
  border-radius: 50%;
}

.overlay > aside {
  height: 100%;
  box-shadow: 0 7px 20px 0 #dddddd;
  z-index: 1;
  background-color: #fff;
}

.overlay > section {
  overflow-y: auto;
  width: 100%;
  background-color: #f5f5f5;
  height: 100%;
}

.popup-card {
  background-color: red;
  width: 200px;
  height: 100px;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #ddd;
  width: 79%;
}

.overlay .open-asid {
  padding-left: 14.5rem;
}

.aside-list-open {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 5rem);
  align-items: center;
  padding-inline: 1rem;
}

.active-aside-items-list:hover .icon {
  color: #ffffff;
}

.active-aside-items-list:hover p {
  color: #ffffff;
}
.active-aside-items-list {
  background-color: #fff;
  color: #6f2da8 !important;
}

.aside-items-list li {
  width: 2.8rem;
  height: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 100%;
}

.aside-items-list-open li {
  width: 158px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: left;
  /* margin: 20px 0; */
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 24px;
  padding-left: 9px;
  gap: 10px;
}

.aside-items-list {
  width: 100%;
}

.aside-items-list li:hover {
  background-color: #6f2da8;
  color: #fff;
  cursor: pointer;
}

.aside-items-list-open li:hover {
  background-color: #6f2da8;
  color: #fff;
  cursor: pointer;
}

.plan-container {
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #f1f1f1;
  display: flex;
}

/* auth */

.auth-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-from {
  max-width: 50vw;
  padding: 0 6rem;
  display: flex;
  align-items: flex-start;
  justify-content: start;
  flex-direction: column;
}

.auth-from img {
  width: 60px;
  height: 60px;
}

.auth-from img {
  width: 60px;
  height: 60px;
}

.input-field-style {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  padding: 10px 10px;
  height: 54px;
  background-color: #fff;
  border: 1px solid #dadae7;
  margin-top: 1rem;
}

.input-field-style > input {
  width: 100%;
  border: none;
  outline: none;
}

.scrap-image {
  width: 45px;
  height: 45px;
}

.raise-pickups {
  display: flex;
  border: 1px solid #dadae7;
  border-radius: 10px;
  margin-top: 10px;
}

.raise-pickups-section {
  width: 50%;
  border-radius: 10px;
  /* height: 50vh; */
  padding: 10px 20px;
}

.time-line-page {
  width: 55vw;
}

.time-line {
  flex-direction: column;
  display: flex;
  align-items: center;
}

.user-chip {
  background: #ececec;
  width: fit-content;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  margin: 8px 0 0 0;
}

.user-chip img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0px 5px 0 0;
}

.time-line-dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.time-line-left-step {
  width: 50vw;
}

.timeline-card {
  height: 15vh;
  width: 30%;
  position: absolute;
  top: 0;
  left: 49.3%;
  margin-top: 10px;
  /* background-color: red; */
}

.timeline-card > header {
  display: flex;
}

.timeline-card > header > span {
  display: block;
  width: 25px;
  height: 25px;
  background: green;
  border-radius: 50%;
}

.timeline-card > header > p {
  margin-left: 15px;
}

.timeline-body {
  /* background: blue; */
  width: 85%;
  height: 60%;
  margin-left: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 0;
}

.timeline-body > div {
  height: 100%;
}

.timeline-body > div > span {
  display: block;
  width: 5px;
  height: 100%;
  background: green;
  border-radius: 5px;
}

.time-line-left-step {
  width: 50vw;
}

.update-box {
  background: #fff;
  padding: 10px;
  border-radius: 6px;
  width: 100%;
}

.timeline-container {
  /* background-color: red; */
  position: relative;

  height: 100vh;
}

.grey-line {
  width: 5px;
  height: 100vh;
  background: #717d96;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  /* z-index: 100; */
}

.loading-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  position: absolute;
  z-index: 2;
}

.loading-page img {
  width: 100px;
  height: 100px;
}

.line {
  width: 2px;
  background-color: #717d96;
  height: 80px;
}

.time-line {
  flex-direction: column;
  display: flex;
  align-items: center;
}

.time-line-box {
  background-color: #fff;
  border-radius: 5px;
  border-left: 4px solid #fff;
  box-shadow: 2px 2px 12px rgb(243, 243, 243);
  padding: 15px;
}

.selected-network2 {
  cursor: pointer;
  padding: 10px;
  background: #ece4f4;
  width: 100%;
  padding: 20px;
  /* background: red; */
}

.unselected-network2 {
  cursor: pointer;
  padding: 10px;
}
.icon-box {
  background-color: rgba(236, 228, 244, 1);
  width: 90px;
  height: 80px;
  border-radius: 10px;
}

.new-notification-indicator {
  background: tomato;
  background-color: #ff1493;
  border-radius: 4px;
  color: #fff;
  font-size: 10px;
  height: 16px;
  position: absolute;
  top: 12px;
  /* right: 10px;   */

  width: 18px;
  text-align: center;
}

.dashboard-content {
  flex: 1;
  padding: 0 15px;
  /* margin-right: 21%; */
}

.notification-item {
  background-color: #fff;
  border-bottom: 1px solid #dadae7;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 15px 10px;
  position: relative !important;
}

.icon {
  font-weight: bold;
}

.icon-bg {
  background-color: #f3ecf9;
  border-radius: 50%;
  color: #6f2da8;
  font-size: 2rem;
  height: 3rem !important;
  margin: 0;
  padding: 15px;
  width: 3rem !important;
  justify-content: center;
  align-items: center;
  display: flex;
}
.normal-text {
  font-size: 16px;
}
.notification-description {
  color: #717d96;
  font-family: circular-light;
  font-style: normal;
  /* font-weight: 500; */
  line-height: 18px;
  white-space: pre-line;
}

.unread-point {
  background-color: #ff1493;
  border-radius: 50%;
  height: 7.5px;
  margin-top: 5px;
  width: 7.5px;
}

.semi-bold-notification {
  color: #263238;
  font-size: 14px;
  font-weight: 450;
  line-height: 18px;
  margin-bottom: 5px;
}

.scrap-card {
  background-color: #fff;
  border-radius: 10px;
  border-left: 4px solid #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
  padding: 15px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.small-text {
  font-size: 0.9rem;
}

.hide-focus-underline {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  caret-color: transparent;
  /* Add any other styles you want */
}

.checkbox-unchecked {
  border: 2px solid #bbbbbb;
  width: 13.5px;
  height: 13.5px;
  border-radius: 3px;
}

.checkbox-checked {
  width: 13.5px;
  height: 13.5px;
  border-radius: 3px;
}

.card-tag > span {
  font-size: 1rem;
}

.notifcation-unseen-background:hover {
  background: #fff;
}

.notifcation-unseen-background {
  background: #ebe0f466;
}

.tableHover:hover {
  cursor: pointer;
  background: #ececec !important;
  color: #000 !important;
}

table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

.dashboardtable th,
.dashboardtablev2 th,
.scrapstable th,
.accounttable th,
.locationtable th,
.mynetwork th,
.billingTable th {
  text-align: center;
  border-block: 1px solid #eaecf0;
  background-color: #f9fafb;
  color: #6f2da8;
}

th:nth-child(1) {
  border-left: 1px solid #eaecf0;
  border-top-left-radius: 20px;
}
.dashboardtable th:nth-child(6) {
  border-right: 1px solid #eaecf0;
  border-top-right-radius: 20px;
}
.dashboardtablev2 th:nth-child(9) {
  border-right: 1px solid #eaecf0;
  border-top-right-radius: 20px;
}
.scrapstable th:nth-child(3) {
  border-right: 1px solid #eaecf0;
  border-top-right-radius: 20px;
}
.accounttable th:nth-child(7) {
  border-right: 1px solid #eaecf0;
  border-top-right-radius: 20px;
}
.locationtable th:nth-child(4) {
  border-right: 1px solid #eaecf0;
  border-top-right-radius: 20px;
}
.mynetwork th:nth-child(9) {
  border-right: 1px solid #eaecf0;
  border-top-right-radius: 20px;
}
.billingTable th:nth-child(5) {
  border-right: 1px solid #eaecf0;
  border-top-right-radius: 20px;
}

.dashboardtable td,
.dashboardtablev2 td,
.scrapstable td,
.accounttable td,
.locationtable td,
.mynetwork td,
.billingTable td {
  text-align: center;
  border-block: 1px solid #dadae7;
  color: #000;
}
td:nth-child(1) {
  border-left: 1px solid #dadae7;
}
.dashboardtable td:nth-child(6) {
  border-right: 1px solid #dadae7;
  /* display: flex; */
  justify-content: center;
}
.dashboardtablev2 td:nth-child(9) {
  border-right: 1px solid #dadae7;
  /* display: flex; */
  justify-content: center;
}
.scrapstable td:nth-child(3) {
  border-right: 1px solid #dadae7;
  justify-content: center;
}
.accounttable td:nth-child(7) {
  border-right: 1px solid #dadae7;
  justify-content: center;
}
.locationtable td:nth-child(4) {
  border-right: 1px solid #dadae7;
  justify-content: center;
}
.mynetwork td:nth-child(9) {
  border-right: 1px solid #dadae7;
  /* display: flex; */
  justify-content: center;
}
.billingTable td:nth-child(5) {
  border-right: 1px solid #dadae7;
  justify-content: center;
}

/* table.striped > tbody > tr:nth-child(odd) {
  background-color: #FAF4FF;
} */

ul.container {
  width: 648px;
  height: 555px;
  background: transparent;
  border-radius: 10px;
  display: flex;
  position: absolute;
  top: 15rem;
  left: 4rem;
}

li.stacked-card {
  width: 648px;
  height: 555px;
  border-radius: 10px;
  display: flex;
}

.stack-texts {
  color: #ffffff;
  font-family: "circular";
  position: absolute;
  bottom: 0;
  background: linear-gradient(
    0deg,
    rgba(111, 45, 168, 0.6) 2.46%,
    rgba(22, 0, 41, 0.6) 100%
  );
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.linear-semicircle {
  background: linear-gradient(180deg, #6f2da8 0%, #120320 100%);
  width: 800px;
  height: 800px;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.ESG-btn {
  background: linear-gradient(180deg, #6f2da8 100%, #b055ff 100%);
  color: #fff;
  padding: 10px;
  border-radius: 30px;
  width: 255px;
  height: 44px;
  font-size: 16px;
  margin: 10px;
}

.input-border {
  border: 1px solid #c5c5c5;
  border-radius: 8px;
}

.scrapstable div > div {
  width: 200px;
  background-color: #f2f1f7;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.scrapstable div > div > img {
  border-bottom: 0px;
  height: 70px;
  width: 70px;
  margin-block: 30px;
  border-radius: 100%;
}

.scrapstable div > div > div {
  background-color: #fafafa;
  border-top: none;
  width: 100%;
  padding-block: 0.6rem;
}

.scrapstable-variant {
  display: inline-block;
  width: 60%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  line-height: 1;
}

.scrapstable-isMobile div > div {
  width: 41.05%;
  /* height: 140px; */
  border-radius: 10px;
}

.scrapstable-isMobile div > div > img {
  border-bottom: 0px;
  height: 70px;
  width: 70px;
  margin-block: 30px;
  border-radius: 100%;
}

.scrapstable-isMobile div > div > div {
  background-color: #fafafa;
  border-top: none;
  width: 100%;
  padding-block: 0.6rem;
}

.scrapstable-isMobile div > div > div > label {
  display: inline-block;
  width: 60%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  line-height: 1;
}

.lowercase {
  text-transform: lowercase;
}

.single-pickup-map {
  width: 50vw;
  border-radius: 10px;
}

.download-app-btn {
  border-radius: 4px;
  padding-inline: 2rem;
}

.download-app-btn > p {
  font-size: x-large;
}

.rounded {
  border-radius: 10px;
}

.pointer {
  cursor: pointer;
}

.first-time-container {
  height: 100vh;
}

.permissions-container {
  background: #f9fafb;
  border-radius: 6px;
}

.permissions-container-children {
  width: 50%;
  padding-inline: 2rem;
}

.lh-0 {
  line-height: 0px;
}

.fab-style {
  width: 60px;
  height: 60px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  background: linear-gradient(180deg, #6f2da8 0%, #ac52f9 100%);
  box-shadow: 0px 16px 16px 0px rgba(116, 48, 175, 0.24);
}

.fab-style-esg {
  width: 200px;
  height: 40px;
  position: absolute;
  bottom: 40px;
  right: 30px;
  cursor: pointer;
  position: fixed;
  z-index: 99;
  background: linear-gradient(180deg, #6f2da8 0%, #ac52f9 100%);
  box-shadow: 0px 16px 16px 0px rgba(116, 48, 175, 0.24);
}

.dialogue-box {
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 20px;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
  border: 1px solid #ececec;
  /* margin-bottom: 10vh; */
  margin-top: 20px;
  max-height: 60vh;
}

.first-time-button {
  margin-bottom: 20vh;
}

.map-content {
  width: 30vw;
  padding: 20px;
  overflow-y: auto;
}

.add-location-container {
  border: 1px solid #ececec;
  display: flex;
}

.add-location-map {
  width: 30vw;
  position: relative;
  height: 42vh;
  display: flex;
}

.first-time-aside {
  background-color: #fff;
  width: 25%;
  padding: 20px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.first-time-aside-list-container {
  /* background-color: blue; */
  height: 80%;
  margin-top: 10px;
}

.scrap-select {
  width: 24%;
  /* height: 150px; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  margin-right: 1%;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 10px;
  border-radius: 10px;
}

.scrap-selected {
  width: 24%;
  box-shadow: 0 4px 6px #00000033;
  background: #fafafa;
  margin-right: 1%;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 10px;
  border-radius: 10px;
}

.scrap-select2 {
  width: 24%;
  /* height: 150px; */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  margin-right: 1%;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 10px;
  border-radius: 10px;
}

.scrap-selected2 {
  width: 23%;
  /* height: 150px; */
  box-shadow: 0 4px 6px #00000033;
  /* background: #ebe0f4; */
  background: #fafafa;
  margin-right: 1%;
  cursor: pointer;
  overflow: hidden;
  margin-bottom: 10px;
  border-radius: 10px;
}

.scrap-select > img {
  width: 100%;
  height: 60%;
  object-fit: cover;
  border: 1px solid #d0d5dd;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 0px;
}

.scrap-selected > img {
  width: 100%;
  height: 60%;
  object-fit: contain;
  background: #fff;
  border: 1px solid #6f2da8;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 0px;
}

.scrap-select2 > img {
  width: 100%;
  height: 60%;
  object-fit: cover;
  border: 1px solid #d0d5dd;
  border-bottom: 0px;
}

.scrap-selected2 > img {
  width: 100%;
  height: 60%;
  object-fit: cover;
  background: #fff;
  border: 1px solid #6f2da8;
  border-bottom: 0px;
}

.check-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 7.5px;
  right: 7.5px;
}

.add-network-modal {
  border-radius: 10px;
}

.btn-rounded {
  border-radius: 50px;
  width: 40px;
  height: 40px;
  background: #fff;
  border: 1px solid #ebe0f4;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.cancel-invite-btn {
  background: #fff8f8;
  border-radius: 4px;
  border: 1px solid orangered;
  padding-block: 0.2rem;
  padding-left: 5px;
  text-align: center;
}

.remainder-btn {
  background: #6f2da8;
  border-radius: 6px;
  padding-block: 0.2rem;
  padding: 5px 10px;
}

.block-btn {
  color: #ea4335;
  border-radius: 6px;
  padding-block: 0.2rem;
  border: 1px solid orangered;
  /* padding: 6px 5px; */
  width: 90px;
}

.new-select-dropdown {
  background-color: #fafafa;
  border-radius: "8px";
  height: 45px;
  appearance: none;
  padding: 10px;
  color: #a7a7a7;
}
.button-2 {
  background-color: #ece4f4;
  color: #6f2da8;
}
.button-2:hover {
  color: #ffff;
}
.dataResult {
  margin-top: 5px;
  width: 100%;
  height: fit-content;
  background-color: white;
  box-shadow: 0px 2px 10px 0px rgba(124, 141, 181, 0.12);
  overflow: hidden;
  overflow-y: auto;
  position: absolute;
  border-radius: 6px;
  z-index: 999;
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataResult .dataItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
}

.dataItem p {
  margin-left: 10px;
}

.sb-hover:hover {
  background-color: #ece4f4;
}

.order-box {
  padding: 1rem;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  background: #fff;
}

.order-box-selected {
  /* border: 2px solid #6f2da8 !important;
   */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.order-box:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.order-container {
  gap: 10px;
  width: 30%;
  height: 75vh;
  overflow-y: auto;
  padding: 0.5rem;
}

.order-container::-webkit-scrollbar {
  width: 0.4em;
}

.order-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  outline: 1px solid #f7f7f7;
  border-radius: 20px;
}

.order-all-pickups-btn {
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 5rem;
  padding: 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.pg-container {
  background: #f5f5f5 !important;
  width: 100%;
  height: 5px;
  margin: 10px 0;
}

.pg-container > div {
  background: #6f2da8;
  width: 50%;
  height: 100%;
  border-radius: 10px;
}

.order-pickups {
  background: #fff;
  width: 70%;
  height: 75vh;
  padding: 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.order-pickups > main {
  /* background: red; */
  height: 100%;
  overflow-y: auto;
}

.order-all-pickups-btn > p {
  font-size: 1.2rem;
}

.order-all-pickups-btn:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.dropdown-content {
  width: 200px !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dropdown-content li > a,
.dropdown-content li > span {
  border-bottom: 1px solid #f0f0f0;
}
.dropdown-content li > a:hover,
.dropdown-content li > span:hover {
  background-color: #f0f0f0 !important;
  color: #6f2da8 !important;
}
.tabs {
  border-radius: 8px;
}

.tabs .indicator {
  height: 100%;
  background: #6f2da82d;
}

.progress-container {
  width: 100%;
  background-color: #f0f0f0;
  border-radius: 10px;
  margin: 10px 0;
}

/* The actual progress bar */
.progress-bar {
  width: 0;
  border-radius: 10px;
  height: 30px;
  background-color: #4caf50;
  text-align: center;
  line-height: 30px;
  color: white;
}
.custom-badge {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: linear-gradient(
    45deg,
    #3498db,
    #8e44ad
  ); /* Gradient background */
  color: #fff;
}

.custom-badge p {
  font-size: 0.8em;
}

.ranking-card {
  background: white;
  border-radius: 10px;
  width: 22%;
}

.ranking-card-header {
  background: #f9f9f9;
  padding-block: 1rem;
  padding-inline: 2rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ranking-tag {
  width: 3rem;
  height: 3rem;
  margin-right: 1.25rem;
}

.disable-focus {
  outline: none;
  box-shadow: none;
}

@media screen and (max-width: 1400px) {
  .time-line-left-step {
    width: 100%;
  }

  .time-line-page {
    width: 100%;
    padding: 20px;
  }

  .contract {
    width: 100%;
  }

  .group {
    width: 100%;
  }

  .overlay .open-asid {
    padding-left: 0;
  }

  .aside-list-open {
    background-color: #25174d;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
  }

  .certificate {
    width: 100%;
  }

  .dialogue-box {
    width: 90%;
  }
}

@media screen and (max-width: 1050px) {
  .overlay .opne-asid {
    padding: 20px;
    position: fixed;
  }

  .fab-style {
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    background: linear-gradient(180deg, #6f2da8 0%, #ac52f9 100%);
    box-shadow: 0px 16px 16px 0px rgba(116, 48, 175, 0.24);
  }

  .header-notification {
    width: 95vw !important;
    top: -2.5rem;
    left: 10px;
    padding-bottom: 2rem;
  }

  .auth-form {
    width: 100%;
  }

  .aside-list {
    display: none;
  }

  .overlay section {
    padding: 10px;
  }

  .main-dashboard {
    display: flex;
    flex-direction: column;
  }

  .filter-location {
    width: 90%;
    right: 0;
    left: 14px;
  }

  .certificate {
    font-size: 12px;
    padding: 0;
    background-image: none;
  }

  .certificate-content {
    width: 100%;
    padding: 10px;
  }

  .percent-tag {
    width: 40px;
    height: 40px;
    background-color: #6f2da8;
    position: absolute;
    top: -5px;
    left: 0px;
    font-size: 9px;
  }
}

@media screen and (max-width: 790px) {
  .auth-page {
    background-image: none;
  }

  .auth-from {
    min-width: 100vw;
    padding: 0 1.4rem;
  }

  .add-location-map {
    width: 100%;
    height: 80vh;
  }

  .add-location-container {
    flex-direction: column;
    /* background: red; */
    width: 100%;
    border: none;
  }

  .map-content {
    width: 100%;
    padding: 0;
    margin-bottom: 10px;
  }

  .add-location-map {
    width: 90vw;
    height: 50vh;
  }

  .first-time-aside {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border: none;
    box-shadow: none;
  }

  .dashboard-content {
    width: 100%;
    padding: 0;
  }

  .quantity-channelized-chart {
    width: 100%;
  }

  .scrap-select {
    width: 45%;
  }

  .scrap-selected {
    width: 45%;
  }
}
